import axios from 'axios'
import config from '../config/default'

const UpdateLocalData = async () => {
    const data = JSON.parse(localStorage.getItem('data'))
    const token = localStorage.getItem('token')
    console.log(data.userId)
    const response = await axios.post(`${config.server.link}/user/getUser`,{userId: data.userId},
        {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    )
    if(!response){
        console.error('Error in updating local data!');
        return;
    }
    const updatedData = response.data;
    console.log(updatedData)
    localStorage.setItem('data', JSON.stringify(updatedData))
}
export default UpdateLocalData;
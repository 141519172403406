import './Support.css'
import AuthNav from '../../Components/Common/AuthNav/AuthNav'

import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Footer from '../../Components/Home/Footer/Footer';


const Support = () => {
    return(
        <div className='support-main' >
            <AuthNav/>
            <div className='support-main-container' >
                <h2>Contact us</h2>
                <h4>All the links to our official support team given here</h4>
                <div className='support-main-icon-container' >
                    <a className='social-container-1' href='https://t.me/hamsterpilotgroup' target='_blank' rel='noreferrer'><TelegramIcon style={{color: 'white'}}/></a>
                    <a className='social-container-1' href='https://api.whatsapp.com/send?phone=17867468741&text=Hi%20Hamster%20Pilot%20Team%2C%20I%20would%20like%20to%20know%20more%20about%20Hamster%20Pilot' target='_blank' rel='noreferrer'><WhatsAppIcon style={{color: 'white'}}/></a>
                    <a className='social-container-1' href='https://www.instagram.com/hamster.pilot' target='_blank' rel='noreferrer'><InstagramIcon style={{color: 'white'}}/></a>
                    <a className='social-container-1' href='https://x.com/hamster_pilot?s=08' target='_blank' rel='noreferrer'><XIcon style={{color: 'white'}}/></a>
                    <a className='social-container-1' href='https://www.facebook.com/hamsterpilot?mibextid=ZbWKwL' target='_blank' rel='noreferrer'><FacebookIcon style={{color: 'white'}}/></a>
                    <a className='social-container-1' href='https://www.youtube.com/@HamsterPilot' target='_blank' rel='noreferrer'><YouTubeIcon style={{color: 'white'}}/></a>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
export default Support
import './Card1.css'
import TaskWidget3 from '../../../Components/Common/TaskWidget3/TaskWidget3'

const Card1 = ({image, amount}) => {
    return(
        <div className='card1-main' >
            <div className='card1-main-logo' >
                <img src={image} alt='nfts' />
            </div>
            <div className='card1-main-container' >
                <h3>Hamster Cadet NFT Tasks</h3>
                <div className='card1-main-task-container' >
                    <a href='https://t.me/hamsterpilotgroup' target='_blank' rel='noreferrer' >
                        <TaskWidget3 title='Join our official Telegram Channel and Share it' />
                    </a>
                    <a href='https://x.com/hamster_pilot?s=08' target='_blank' rel='noreferrer' >
                        <TaskWidget3 title='Follow our official Twitter page and Like, Retweet and Comment in our posts' />
                    </a>
                    <a href='https://www.instagram.com/hamster.pilot/' target='_blank' rel='noreferrer' >
                        <TaskWidget3 title='Follow our Instagram Account and Share it' />
                    </a>
                    <a href='https://t.me/hamsterpilotgroup' target='_blank' rel='noreferrer' >
                        <TaskWidget3 title='Refer Hamster Pilot to a minimum of 5 friends' />
                    </a>
                    {/* <TaskWidget3 title='Create Account and connect Trust Wallet' navigateLink='/user/connectTrustWallet' /> */}
                    <TaskWidget3 title={`Trade minimum ${amount}$ worth of Hamster Pilot (HMSTR) Token in your wallet`} navigateLink='/howToTrade' />
                    <a href='https://forms.gle/Sqem26v7DfQHYhxo9' target='_blank' rel='noreferrer' >
                        <div className='taskWidget3-main' style={{backgroundColor: '#35FFB2', justifyContent:'center'}} >
                            <h2 style={{color:'black'}} ><strong>MINT</strong></h2>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}
export default Card1;
import './HowToTrade.css'
import AuthNav from '../../Components/Common/AuthNav/AuthNav';

import HowToTradeImage from '../../Assets/HowToTradePage/HowToTradeImage.svg'



const HowToTrade = () => {

    
    return(
        <div className="howToTrade-main" >
            <AuthNav/>
            <div className="howToTrade-main-container" >
                <div className='howToTrade-main-image' >
                    <img src={HowToTradeImage} alt='HeaderImage' />
                </div>
                <div className='howToTrade-main-content' >
                    <h2>HOW TO TRADE</h2>
                    <div className='howToTrade-main-content-list' >
                        <span>Step 1: Install Metamask</span>
                        <p>Open your browser and visit <a href='https://metamask.io' target='_blank' rel='noreferrer'><u style={{color:'#EE8505'}} >metamask.io</u></a> or <a href='https://trustwallet.com' target='_blank' rel='noreferrer' ><u style={{color: '#EE8505'}} >trustwallet.com</u></a> to download the MetaMask or Trust Wallet Extension and set up a wallet. You can also download the app on iPhone & Android.</p>
                        
                        <span>Step 2: Send BNB to your wallet</span>
                        <p>Transfer BNB to your wallet from your existing wallet (e.g. Binance, Bybit, etc). Be sure to use BSC network.</p>
                        
                        <span>Step 3: Connect to <a href='https://pancakeswap.finance' target='_blank' rel='noreferrer'><u style={{color:'#EE8505'}} >Pancakeswap.finance</u></a></span>
                        <p>Make sure the network tab in your wallet is set to "Binance Smart Chain".</p>
                        
                        <span>Step 4: Swap $BNB For $HMSTR</span>
                        <p>Paste our contract address into Pancake Swap to swap $BNB for $HMSTR. Always confirm the official contract 
                            <br/>
                            address: 0x7ff5AeD25388FECA5a89844DCBAf97a022177e94
                        </p>
                        
                        <span>Step 5: Fasten your seatbelt and soar above the skies.</span>
                        <p>Sit back and relax as our Hamster Pilots take you on a journey beyond the skies</p>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default HowToTrade;
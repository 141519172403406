import Image_Auth from "../../Assets/Auth/Image_Auth.png";
import TextField from "@mui/material/TextField";
import Swal from 'sweetalert2'

import config from '../../config/default'
import "../Common.css";
import "../CommonCSS/Common.css";
import { useNavigate } from "react-router-dom";
import LogoFull from '../../Assets/Auth/LogoFull.png'
import {useState} from 'react'
import axios from 'axios'
import { CircularProgress } from "@mui/material";

const VerifySignupOtp=()=>{

    let navigate = useNavigate()
    const [otp, setOtp]=useState('');


    const [verifyOtpButton, setVerifyOtpButton]=useState('Verify OTP')    

    const handleVerifyOtp=async(e)=>{
        e.preventDefault();
        setVerifyOtpButton(<CircularProgress color='inherit' />)

        const data={
            
            userId:sessionStorage.getItem('tempUserId'),
            otp:otp
        }

        await axios.post(`${config.server.link}/user/signupAfterVerification`, data)
            .then((response) => {
                setVerifyOtpButton("Verify OTP")
                if (response.status === 200) {
                    Swal.fire({text:'OTP Verified!', icon: "success"})
                        .then(() => {
                            navigate("/login")
                        })
                } else if (response.status === 202) {
                    Swal.fire({text:`${response.data.message}`,icon: "error"})

                }
            })

    }


    return(
        <div className="authCommon">
            <div className="authBlock-image">
                <div className="welcomeLogo" onClick={()=>navigate('/')} >
                    <img src={LogoFull} alt="Logo" /> <p></p>
                </div>
                <img src={Image_Auth} alt="auth" className="authImage"/>
                <div className="authCopyright" ><p>&copy; Bitcoin Payment Bank. All rights reserved.</p></div>
            </div>
            <div className="authBlock-content">
                <h2 className="authBlock-content-head">Verify OTP</h2>
                <form className="login-form">
                    <div className="form-input">
                        <TextField
                            required
                            id="outlined-required"
                            label="Enter OTP"
                            defaultValue=""
                            fullWidth="true"
                            style={{width: '100%'}}
                            value={otp}
                            onChange={(e)=>setOtp(e.target.value)}  
                        />
                    </div>

                    
                    <button className="auth-button" onClick={handleVerifyOtp} >{verifyOtpButton}</button>

                </form>
            </div>
            <div className="authCopyright-mobile">
          <p>&copy; Bitcoin Payment Bank. All rights reserved.</p>
        </div>
        </div>
    )
}
export default VerifySignupOtp;
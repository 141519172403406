import Image_Auth from "../../Assets/Auth/Image_Auth.png";
import TextField from "@mui/material/TextField";

import "../Common.css";
import "../CommonCSS/Common.css";
import { useNavigate } from "react-router-dom";
import LogoFull from '../../Assets/Auth/LogoFull.png'
import {useState, useRef} from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import {toast} from 'react-hot-toast'
import { CircularProgress } from "@mui/material";
import config from '../../config/default'

const ForgotPassword=()=>{

    const navigate = useNavigate()

    const [email, setEmail] = useState("");
    const [sendOtpButton, setSendOtpButton]=useState("SUBMIT");
    const [isOtpSent, setIsOtpSent]=useState(false)
    
    
    const [otp, setOtp]=useState();
    const [password, setPassword]=useState();
    const [confirmPassword, setConfirmPassword]=useState();
    const [changePasswordButton, setChangePasswordButton]=useState('Change Password');

    const myRef = useRef(null);
    const scrollToSection = () => {
        setTimeout(()=>{
            if (myRef.current) {
                myRef.current.scrollIntoView({ 
                  behavior: 'smooth' // Optional for smooth scrolling
                });
              }
        },1000)
      };

      const handleSendOtpButton= async(e)=>{
        e.preventDefault();
        setSendOtpButton(<CircularProgress color='inherit' />)
        if(email===''){
            toast.error('Invalid email!')
              setSendOtpButton('Send OTP')
              return;
        }

        const data={email: email}
        await axios.post(`${config.server.link}/user/sendUserPasswordResetOtp`, data)
                .then(async (response)=>{
                    if(response.status === 200){
                        setSendOtpButton('OTP sent!')
                        setIsOtpSent(true)
                        await sessionStorage.setItem('otpId', response.data.userId)
                        scrollToSection()
                    }
                    if(response.status===202){
                        toast.error('User is not registered!')
                    }
                    
                })
    }


    const handleChangePasswordButton=async(e)=>{
        e.preventDefault();
        setChangePasswordButton(<CircularProgress color='inherit' />)

        const data={
            password:password,
            cPassword:confirmPassword,
            otpId:sessionStorage.getItem('otpId'),
            otp:otp
        }

        await axios.post(`${config.server.link}/user/changePassword`, data,
            {
                headers:{
                    'Authorization': `Bearer ${sessionStorage.getItem('otpId')}`
                }
            })
            .then((response) => {
                setChangePasswordButton("Change Password")
                if (response.status === 200) {

                    Swal.fire({text:`${response.data.message}`, icon: "success"})
                        .then(() => {
                            navigate("/login")
                        })
                } else if (response.status === 202) {
                    toast.error(response.data.message)

                }
            })

    }

    return(
        <div className="authCommon">
            <div className="authBlock-image">
                <div className="welcomeLogo" onClick={()=>navigate('/')} >
                    <img src={LogoFull} alt="Logo" /> <p></p>
                </div>
                <img src={Image_Auth} alt="auth" className="authImage"/>
                <div className="authCopyright" ><p>&copy; Hamster Pilot. All rights reserved.</p></div>
            </div>
            <div className="authBlock-content">
                <h2 className="authBlock-content-head">Reset Password</h2>
                <form className="login-form">
                    <div className="form-input">
                        <TextField
                            required
                            id="outlined-required"
                            label="Email"
                            defaultValue=""
                            fullWidth="true"
                            style={{width: '100%'}}
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}  
                            disabled={isOtpSent}
                        />
                    </div>

                    
                    <button 
                        className="auth-button" 
                        onClick={handleSendOtpButton} 
                        disabled={isOtpSent} 
                        style={{backgroundColor: isOtpSent && '#00ab41'}}
                    >{sendOtpButton}</button>

                    {
                        isOtpSent &&

                        (<div id='scrollFocus' ref={myRef}>
                            <br/>
                            <br/>
                            <form>
                                <div className="form-input">
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="OTP"
                                        defaultValue=""
                                        fullWidth="true"
                                        style={{width: '100%'}}
                                        value={otp}
                                        onChange={(e)=>setOtp(e.target.value)}  
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="form-input">
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Password"
                                        type="password"
                                        fullWidth="true"
                                        style={{width: '100%'}}
                                        value={password}
                                        onChange={(e)=>setPassword(e.target.value)}  
                                    />
                                </div>
                                <div className="form-input">
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Confirm Password"
                                        type="password"
                                        fullWidth="true"
                                        style={{width: '100%'}}
                                        value={confirmPassword}
                                        onChange={(e)=>setConfirmPassword(e.target.value)}  
                                    />
                                </div>
                                <button className="auth-button" onClick={handleChangePasswordButton}>{changePasswordButton}</button>
                            </form>
                        </div>)
                    }

                </form>
            </div>
            <div className="authCopyright-mobile">
          <p>&copy; Hamster Pilot. All rights reserved.</p>
        </div>
        </div>
    )
}
export default ForgotPassword;
import './Nft.css'
import AuthNav from '../../Components/Common/AuthNav/AuthNav';

import Card1 from '../../Components/Common/Card1/Card1';
import NFT1 from '../../Assets/NFT/NFT1.png'
import NFT2 from '../../Assets/NFT/NFT2.png'
import NFT3 from '../../Assets/NFT/NFT3.png'



const Nft = () => {
    return(
        <div className='nft-main' >
            <AuthNav color='#fff' />
            <div className='nft-main-container' >
                <h2>Hamster Pilot Exclusive NFT Collections</h2>
                <h4>Get a chance to MINT these Exclusive NFTs by performing simple tasks, before Minting finishes</h4>
                <div className='nft-main-card-container' >
                    <Card1 image={NFT1} amount='500'/>
                    <Card1 image={NFT2} amount='1000'/>
                    <Card1 image={NFT3} amount='1500'/>
                </div>
            
            </div>
        </div>
    )
}
export default Nft;
import './TaskWidget3.css'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import {useNavigate} from 'react-router-dom'



const TaskWidget3 = ({title, navigateLink}) => {

    const navigate = useNavigate()

    return(
        <div className='taskWidget3-main' onClick={()=>navigate(navigateLink)} >
            <h2>{title}</h2>
            <ArrowForwardIosIcon/>
        </div>
    )
}
export default TaskWidget3;
import './AirdropTask.css'
import TaskWidget from '../../Common/TaskWidget/TaskWidget';

const AirdropTask = () => {
    return(
        <div className='airdropTask-main' >
            <div className='airdropTask-main-head' >
                <h2>Complete Airdrop Tasks</h2>
                <h4>Complete Airdrop Tasks to get exclusive NFT and HMSTR Tokens</h4>
            </div>
            <div className='airdopTask-main-tasks' >
                <a href='https://t.me/hamsterpilotgroup' target='_blank' rel='noreferrer' >
                    <TaskWidget title='Join our official Telegram Channel and Share it' />
                </a>
                <a href='https://x.com/hamster_pilot?s=08' target='_blank' rel='noreferrer' >
                    <TaskWidget title='Follow our official Twitter page and Like, Retweet and Comment in our posts' />
                </a>
                <a href='https://www.instagram.com/hamster.pilot' target='_blank' rel='noreferrer' >
                    <TaskWidget title='Follow our Instagram Account and Share it' />
                </a>
                <a href='https://t.me/hamsterpilotgroup' target='_blank' rel='noreferrer' >
                    <TaskWidget title='Refer Hamster Pilot to a minimum of 5 friends' />
                </a>
                <TaskWidget title='Create Account' navigateLink='/signup' />
                <TaskWidget title='Trade minimum 10$ Hamster Pilot (HMSTR) Token in your wallet' navigateLink='/howToTrade' />
            </div>
        </div>
    )
}
export default AirdropTask;
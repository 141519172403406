import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";
import {toast} from 'react-hot-toast'

import config from '../../config/default'
import Image_Auth from "../../Assets/Auth/Image_Auth.png";
import "../Common.css";
import LogoFull from "../../Assets/Auth/LogoFull.png";

const Signup = () => {
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("")
  const [submitButton, setSubmitButton]=useState("SIGNUP");

  const data = {
    'fName': fName,
    'lName': lName,
    'email': email,
    'password': password
  }

  const handleSubmit= async (e)=> {
    e.preventDefault();
    if(!fName || !lName || !email || !password){
      toast.error('Incomplete Data')
      return;
    }

    setSubmitButton(<CircularProgress color="inherit" />)
    if(password!==confirmPassword){
      toast.error('Passwords do not match!');
      setSubmitButton('SIGNUP')
    }else{

      localStorage.setItem("tempData", JSON.stringify({fName: fName, lName: lName}))
      await axios
        .post(`${config.server.link}/user/signup`, data)
        .then(async (response) => {
          if (response.status === 200) {
            sessionStorage.setItem("tempUserId", response.data.userId);
            await axios.post(`${config.server.link}/user/sendSignupVerificationOtp`, {'userId': sessionStorage.getItem('tempUserId')})
                      .then(()=>{
                          navigate('/signup/verify')
                      })
            
          //   swal('', 'Account Created!', 'success').then(()=>navigate('/login'))
          } else if (response.status === 202) {
            
            toast.error(response.data.message);
            setSubmitButton('SIGNUP')
          }
        });
    }
    
      

    
  }

  const navigate = useNavigate();

  return (
    <div className="authCommon">
      <div className="authBlock-image">
        <div className="welcomeLogo" onClick={() => navigate("/")}>
          <img src={LogoFull} alt="Logo" /> <p></p>
        </div>
        <img src={Image_Auth} alt="auth" className="authImage" />
        <div className="authCopyright">
          <p>&copy; Hamster Pilot. All rights reserved.</p>
        </div>
      </div>
      <div className="authBlock-content">
        <h2 className="authBlock-content-head">Create Account</h2>
        <form className="login-form">
          <div className="login-form-name" autoComplete="name">
            <div className="form-input">
            <TextField
                required
                id="outlined-required"
                label="First Name"
                defaultValue=""
                fullWidth="true"
                style={{width: '100%'}}
                type="text"
                value={fName}
                onChange={(e) => setFName(e.target.value)}
                autoComplete="given-name"
              />
            </div>
            <div className="form-input">
              <TextField
                required
                id="outlined-required"
                label="Last Name"
                defaultValue=""
                fullWidth="true"
                style={{width: '100%'}}
                type="text"
                value={lName}
                onChange={(e) => setLName(e.target.value)}
                autoComplete="family-name"

              />
            </div>
          </div>
          <div className="form-input">
            <TextField
              required
              id="outlined-required"
              label="Email"
              defaultValue=""
              fullWidth="true"
              style={{width: '100%'}}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="form-input">
            <TextField
              required
              id="outlined-required"
              label="Password"
              defaultValue=""
              fullWidth="true"
              style={{width: '100%'}}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="form-input">
            <TextField
              required
              id="outlined-required"
              label="Confirm Password"
              defaultValue=""
              fullWidth="true"
              style={{width: '100%'}}
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>

          <button className="auth-button" onClick={handleSubmit}>{submitButton}</button>
          <div className="auth-already">
            <p>
              Already have an account?{" "}
              <a href="##" onClick={() => navigate("/login")}>
                Login
              </a>
            </p>
          </div>
        </form>
      </div>
        <div className="authCopyright-mobile">
          <p>&copy; Hamster Pilot. All rights reserved.</p>
        </div>
    </div>
  );
};
export default Signup;

import './Dashboard.css'
import HeaderImage1 from '../../Assets/Home/HeaderImage1.png';

import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom';
import CopyAllIcon from '@mui/icons-material/CopyAll'
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import GraphImage from '../../Assets/Dashboard/GraphImage.svg';

import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import {toast} from 'react-hot-toast'
import {CopyToClipboard} from 'react-copy-to-clipboard'

import Swal from 'sweetalert2'

import UpdateLocalData from '../../Utils/FetchService';
const Dashboard = () => {

    const navigate = useNavigate()
    const [totalBalance, setTotalBalance]=useState(JSON.parse(localStorage.getItem('data')).balance || 0);
    const [totalInvestment, setTotalInvestment]=useState(100)
    const [sliderYear, setSliderYear]=useState(2)
    // Multiplied by 7 because the slider value is set to 2 years by default
    const [estimatedReturn, setEstimatedReturn] = useState(Number(7 * totalInvestment) - Number(totalInvestment))

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    // eslint-disable-next-line
    const PrettoSlider = styled(Slider)({
        color: '#29BBCF',
        height: 8,
        '& .MuiSlider-track': {
          border: 'none',
        },
        '& .MuiSlider-thumb': {
          height: 24,
          width: 24,
          backgroundColor: '#fff',
          border: '2px solid currentColor',
          '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
          },
          '&::before': {
            display: 'none',
          },
        },
        '& .MuiSlider-valueLabel': {
          lineHeight: 1.2,
          fontSize: 12,
          background: 'unset',
          padding: 0,
          width: 32,
          height: 32,
          borderRadius: '50% 50% 50% 0',
          backgroundColor: '#29BBCF',
          transformOrigin: 'bottom left',
          transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
          '&::before': { display: 'none' },
          '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
          },
          '& > *': {
            transform: 'rotate(45deg)',
          },
        },
      });
      const marks = [
        {
          value: 1,
          label: '1Y',
        },
        {
          value: 10,
          label: '10Y',
        },
        
      ];
      const handleSliderChange = (event, newValue) => {
        setSliderYear(Number(newValue))
      }
      function valuetext(value) {
        return `${value} months`;
      }

      const  handleTotalInvestmentChange = (event) =>{
        if(isNaN(event.target.value)){
          toast.error('Invalid value')
          return;
        }
        setTotalInvestment(event.target.value)
      }

    useEffect(()=>{
      UpdateLocalData().then(()=>setTotalBalance(JSON.parse(localStorage.getItem('data')).balance))
      if(Number(sliderYear)===1){
        setEstimatedReturn(Number(4 * totalInvestment) - Number(totalInvestment))
      }else if(Number(sliderYear)===2){
        setEstimatedReturn(Number(7 * totalInvestment) - Number(totalInvestment))
      }else if(Number(sliderYear)>=3 && Number(sliderYear) <= 10){
        setEstimatedReturn(Number(7 * totalInvestment) + (Number(sliderYear)-2)*(Number(totalInvestment)*0.7) - Number(totalInvestment))
      }
    }, [sliderYear, totalInvestment])  


    return(
        <div className="dashboard-main" >
            <div className="dashboard-main-header" >
                <div className="dashboard-main-header-logo" onClick={()=>navigate('/')}>
                    <img src={HeaderImage1} alt='Logo'/>
                    <p>Hamster Pilot</p>
                </div>
                <div className='dashboard-main-contract-address getStarted-nav-contract-address' >
                        <div className='dashboard-main-contract-address-a1 getStarted-nav-contract-address-a1' >
                            <h4>Contract Address</h4>
                            <h2>0x7ff5AeD25388FECA5a89844DCBAf97a022177e94</h2>
                        </div>
                        <div className='dashboard-main-contract-address-a2 getStarted-nav-contract-address-a2' >
                          <CopyToClipboard text='0x7ff5AeD25388FECA5a89844DCBAf97a022177e94' onCopy={()=>toast.success('Copied!')}>
                            <CopyAllIcon style={{color: 'white', cursor: 'pointer'}} />
                          </CopyToClipboard>
                        </div>
                </div>
                <div className="dashboard-main-header-hamburger" >
                <MenuIcon fontSize='large' style={{color: 'white', cursor: 'pointer'}} onClick={handleClick}/>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        
                    >
                        <MenuItem className='hamNav-menu-item' onClick={()=>navigate('/')}>Home</MenuItem>
                        <MenuItem className='hamNav-menu-item' onClick={()=>navigate('/user/dashboard')}>Account</MenuItem>
                        <MenuItem className='hamNav-menu-item' onClick={()=>navigate('/howToTrade')}>How to Trade</MenuItem>
                        <MenuItem className='hamNav-menu-item' onClick={()=>navigate('/support')}>Contact Us</MenuItem>
                        <MenuItem className='hamNav-menu-item' onClick={()=>{localStorage.clear(); navigate('/login') }}>Log out</MenuItem>
                    </Menu>
                </div>
            </div>
            <div className='dashboard-main-card-container' >

                <div className='dashboard-main-balance-card' >
                        <h2>Welcome User,</h2>
                        <h4>Total Balance (Hamster Pilot)</h4>
                        <h1>{totalBalance} <span style={{fontSize:'20px', color:'white'}} >HMSTR</span></h1>
                        <div className='balance-card-button-container' >
                            <a
                              href='https://pancakeswap.finance/?outputCurrency=0x7ff5AeD25388FECA5a89844DCBAf97a022177e94'
                              target='_blank'
                              rel='noreferrer'
                              className='balance-card-trade-button'
                            >
                              <button className='balance-card-trade-button'>TRADE HMSTR</button>
                            </a>
                            <button 
                              className='balance-card-withdraw-button' 
                              onClick={()=>{
                                Swal.fire({
                                      title: "<strong>Contact our <u>Support</u> to claim your tokens.</strong>",
                                      icon: "info",
                                      showCloseButton: true,
                                      focusConfirm: false,
                                      confirmButtonText: `Contact Support`
                                    }).then((result)=>{
                                      if(result.isConfirmed){
                                        navigate('/support')
                                      }
                                    });
                              }} 
                            >WITHDRAW</button>
                        </div>
                </div>
                <div className='dashboard-main-airdrop-card' >
                        <h2>Hamster Pilot Exclusive Airdrop Event</h2>
                        <h3>Be a part of this exclusive airdrop event with a prize pool of $ 2,000,000 in HMSTR Tokens. </h3>
                        <h4>Note: Event is live till December 2024, Airdrop will be given on 5th Jan, 2025</h4>

                        <div className='airdrop-card-button-container' >
                            <button className='airdrop-card-join-button' onClick={()=>navigate('/user/airdrop')}>JOIN AIRDROP <ArrowCircleRightOutlinedIcon/> </button>
                        </div>
                </div>
                <div className='dashboard-main-nft-card' >
                    <h2>Hamster Pilot Exclusive NFT Collection</h2>
                    <h4>Get a chance to MINT these Exclusive NFTs by performing simple tasks, before Minting finishes</h4>
                    <div className='nft-card-button-container airdrop-card-button-container' >
                        <button className='airdrop-card-join-button' onClick={()=>navigate('/user/nft')}>MINT NFT <ArrowCircleRightOutlinedIcon/></button>
                    </div>
                </div>
                <div className='dashboard-main-graph-container' >
                    <img src={GraphImage} alt='Graph' />
                </div>
                <div className='return-calculator-container' >
                        <h2>Investment Return Calculator</h2>
                        <div className='calculator-slider-container' >
                            <div className='calculator-slider-container-value'>
                                <p>Total Investment</p>

                                <span>$<input value={totalInvestment} onChange={handleTotalInvestmentChange}/></span>
                            </div>
                            <div className='calculator-slider-container-value'>
                                <p>Time Period Invested</p>
                                <span>{sliderYear}Y</span>
                            </div>
                            <Slider
                                aria-label="Custom marks"
                                getAriaValueText={valuetext}
                                onChange={handleSliderChange}
                                value={typeof sliderYear === 'number' ? sliderYear : 0}
                                valueLabelDisplay="auto"
                                min={1}
                                max={10}
                                marks={marks}
                            />
                        </div>
                        <div className='return-total-amount-container-main' >
                            <div className='return-total-amount-container' >
                                <p>Invested Amount</p>
                                <span>$ {totalInvestment}</span>
                            </div>
                            <div className='return-total-amount-container' >
                                <p>Estimated Returns</p>
                                <span>$ {estimatedReturn}</span>
                            </div>
                            <div className='return-total-amount-container' >
                                <p>Total Value</p>
                                <span>$ {Number(totalInvestment) + Number(estimatedReturn)}</span>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}
export default Dashboard;
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import ProtectedRoutes from './Utils/ProtectedRoutes';


import Home from './Pages/Home/Home'
import Login from './Pages/Login/Login'
import Signup from './Pages/Signup/Signup'
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword'
import VerifySignupOtp from './Pages/VerifySignupOtp/VerifySignupOtp'
import Dashboard from './Pages/Dashboard/Dashboard'
import HowToTrade from './Pages/HowToTrade/HowToTrade';

import AirdropEvent from './Pages/AirdropEvent/AirdropEvent';
// import ConnectTrustWallet from './Pages/ConnectTrustWallet/ConnectTrustWallet';
import Support from './Pages/Support/Support';
import Nft from './Pages/Nft/Nft';


const App = ()=> {
  return (
    <div className="App">
        
        <Router>
          <Routes>
            {/* Non-protected routes */}
          <Route 
            path='/*'
            element={
                <Routes>

                  {/* Managing the undefined routes */}
                  <Route path='*' element={<Navigate to='/' />} />
                
                  <Route element={<Home/>} path='/' />
                  <Route element={<Login/>} path='/login' />
                  <Route element={<Signup/>} path='/signup' />
                  <Route element={<ForgotPassword/>} path='/forgotPassword' />
                  <Route element={<VerifySignupOtp/>} path='/signup/verify' />
                  <Route element={<HowToTrade/>} path='/howTotrade' />
                  <Route element={<Support/>} path='/support' />
                </Routes>
            } 

          />


            {/* Protected Routes */}
            <Route
              path='/user/*'  //Routes will start with /user/then_the_routes_given_below
              element={
                  <Routes>
                    <Route element={<ProtectedRoutes/>}> {/* Protected */}

                      {/*Managing the undefined routes */}
                      <Route path='*' element={<Navigate to='/' />} />

                      <Route element={<Dashboard/>} path='/dashboard' />
                      <Route element={<AirdropEvent/>} path='/airdrop' />
                      <Route element={<Nft/>} path='/nft' />
                      {/* <Route element={<ConnectTrustWallet/>} path='/connectTrustWallet' /> */}
                    </Route>
                  </Routes>
              }
            />
          </Routes>
        </Router>
    </div>
  );
}

export default App;

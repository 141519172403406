import './AuthNav.css'
import HeaderImage1 from '../../../Assets/Home/HeaderImage1.png'

import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


const AuthNav = ({color}) => {

    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return(
        <div className="authNav-main" >
                <div className="authNav-main-nav-logo" onClick={()=>navigate('/user/dashboard')} >
                    <img src={HeaderImage1} alt='logo' />
                    <p style={{color: color}} >Hamster Pilot</p>
                </div>
                <div className="authNav-main-nav-ham" >

                    <MenuIcon fontSize='large' style={{color: color || 'black', cursor: 'pointer'}} onClick={handleClick}/>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        
                    >
                        <MenuItem className='hamNav-menu-item' onClick={()=>navigate('/')}>Home</MenuItem>
                        <MenuItem className='hamNav-menu-item' onClick={()=>navigate('/user/dashboard')}>Account</MenuItem>
                        <MenuItem className='hamNav-menu-item' onClick={()=>navigate('/howToTrade')}>How to Trade</MenuItem>
                        <MenuItem className='hamNav-menu-item' onClick={()=>navigate('/support')}>Contact Us</MenuItem>
                        <MenuItem className='hamNav-menu-item' onClick={()=>{localStorage.clear(); navigate('/login') }}>Log out</MenuItem>
                    </Menu>
                </div>
            </div>
    )
}
export default AuthNav;
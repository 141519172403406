import Image_Auth from "../../Assets/Auth/Image_Auth.png";
import TextField from "@mui/material/TextField";

import {toast} from 'react-hot-toast'
import config from '../../config/default'
import "../Common.css";
import "../CommonCSS/Common.css";
import { useNavigate } from "react-router-dom";
import LogoFull from '../../Assets/Auth/LogoFull.png';
import {useState} from 'react'
import axios from 'axios'
import { CircularProgress } from "@mui/material";


const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail]=useState('');
    const [password, setPassword]=useState('');
    const [submitButton, setSubmitButton]=useState('LOGIN');

    const handleLogin = async (e)=>{
        e.preventDefault();
        setSubmitButton(<CircularProgress color="inherit" />)
        const login = {
            "email": email,
            "password": password
        };
        // console.log(login);
        await axios.post(`${config.server.link}/user/login`, login)
            .then(async (response) => {
                // console.log(response.data);

                if (response.status === 200) {
                    // swal("Logged In", "Successfully Authorised", "success");
                    await localStorage.setItem("token", response.data.token);
                    await localStorage.setItem("data", JSON.stringify(response.data.user));
                    navigate("/user/dashboard");
                } else if (response.status === 401) {
                    toast.error("Invalid credentials!");
                    // console.log(response.data);
                } else if (response.status === 202) {
                    toast.error(response.data.message)
                } else {
                    toast.error("Some unexpected error occurred.")
                    // console.log(response.data);
                }
                setSubmitButton('LOG IN')
            });

    }

    return (
        <div className="authCommon">
            <div className="authBlock-image">
                <div className="welcomeLogo" onClick={()=>navigate('/')} >
                    <img src={LogoFull} alt="Logo" /> <p></p>
                </div>
                <img src={Image_Auth} alt="auth" className="authImage"/>
                <div className="authCopyright" ><p>&copy; Hamster Pilot. All rights reserved.</p></div>
            </div>
            <div className="authBlock-content">
                <h2 className="authBlock-content-head">Log In</h2>
                <form className="login-form">
                    <div className="form-input">
                        <TextField
                            required
                            id="outlined-required"
                            label="Email"
                            defaultValue=""
                            style={{width: '100%'}}
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}  
                        />
                    </div>

                    <div className="form-input">
                        <TextField
                            required
                            id="outlined-required"
                            label="Password"
                            defaultValue=""
                            style={{width: '100%'}}
                            value={password}
                            onChange={(e)=>setPassword(e.target.value)}
                        />
                    </div>
                    <button className="auth-button" onClick={handleLogin} >{submitButton}</button>
                    <div className="login-fp" ><p onClick={()=>navigate('/forgotpassword')}>Forgot Password?</p></div>

                    <div className="auth-already">
                        <p>
                            Don't have an account?&nbsp;
                            <a href="##" onClick={() => navigate("/signup")}>
                                Signup
                            </a>
                        </p>
                    </div>
                </form>
            </div>
            <div className="authCopyright-mobile">
          <p>&copy; Hamster Pilot. All rights reserved.</p>
        </div>
        </div>
    );
};
export default Login;

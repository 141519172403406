import './AirdropEvent.css'
import AirdropImage1 from '../../Assets/AirdropPage/AirdropImage1.png'

import AuthNav from '../../Components/Common/AuthNav/AuthNav';
import TaskWidget2 from '../../Components/Common/TaskWidget2/TaskWidget2';


const AirdropEvent = () => {

    return(
        <div className='airdropEvent-main' >
            <AuthNav color='#ffffff'/>
            <div className='airdropEvent-main-container' >
                <div className='airdropEvent-main-head' >
                    <h2>Hamster Pilot Exclusive Airdrop Event</h2>
                    <h4>Be a part of this exclusive airdrop event with a prize pool of $ 2,000,000 in HMSTR Tokens. </h4>
                </div>
                <div className='airdropEvent-main-body' >
                    <div className='airdropEvent-main-body-image' >
                        <img src={AirdropImage1} alt='HeaderImage' />
                    </div>
                    <div className='airdropEvent-main-body-task' >
                        <a href='https://t.me/hamsterpilotgroup' target='_blank' rel='noreferrer' >
                            <TaskWidget2 title='Join our official Telegram Channel and Share it' />
                        </a>
                        <a href='https://x.com/hamster_pilot?s=08' target='_blank' rel='noreferrer' >
                            <TaskWidget2 title='Follow our official Twitter page and Like, Retweet and Comment in our posts' />
                        </a>
                        <a href='https://www.instagram.com/hamster.pilot/' target='_blank' rel='noreferrer' >
                            <TaskWidget2 title='Follow our Instagram Account and Share it' />
                        </a>
                        <a href='https://t.me/hamsterpilotgroup' target='_blank' rel='noreferrer' >
                            <TaskWidget2 title='Refer Hamster Pilot to a minimum of 5 friends' />
                        </a>
                        {/* <TaskWidget2 title='Create Account and connect Trust Wallet' navigateLink='/user/connectTrustWallet' /> */}
                        <TaskWidget2 title='Trade minimum 10$ Hamster Pilot (HMSTR) Token in your wallet' navigateLink='/howToTrade' />
                        <a  href='https://forms.gle/PLqefczVxbHff76a9' target='_blank' rel='noreferrer' >
                            <button 
                              className='balance-card-withdraw-button' 
                              style={{width: '100%', fontSize: '28px', padding: '35px',display: 'flex' ,alignItems:'center', justifyContent:'center'}}
                            >
                                CLAIM AIRDROP
                            </button>
                        </a>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                    </div>
                </div>
            </div>
            
        </div>
    )
}
export default AirdropEvent;
import './TaskWidget.css'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
  
import {useNavigate} from 'react-router-dom'
const TaskWidget = ({title, navigateLink}) => {


    const navigate = useNavigate();

    return(
        <div className='taskWidget-main' onClick={()=>navigate(navigateLink)}>
            <h2>{title}</h2>
            <ArrowForwardIosIcon/>
        </div>
    )
}
export default TaskWidget;
import './JoinNow.css'
import HeaderImage1 from '../../../Assets/Home/HeaderImage1.png'

import {useNavigate} from 'react-router-dom'
const JoinNow = () => {


    const navigate = useNavigate();
    return(
        <div className='joinNow-main' >
            <h2>Join the community now!</h2>
            <h4>Join us in the HMSTR journey</h4>
            <img  src={HeaderImage1} alt='HeaderImage' />
            <button onClick={()=>navigate('/signup')} >GET STARTED</button>
        </div>
    )
}
export default JoinNow;